import { QuestionQuiz } from './../interface/quiz.interface';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Post } from '../interface/post.interface';

import { Question, Quiz } from '../interface/quiz.interface';
import { Training } from '../interface/training.interface';
import { User } from '../interface/user';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  BASE_URL = 'https://oim-server.herokuapp.com/api/v1';
  //BASE_URL = 'https://oim-server-app.herokuapp.com/api/v1';
  //BASE_URL = 'https://manta-blog-server.herokuapp.com/api/v1';
  // BASE_URL = 'http://panelblogccpd.santodomingo.gob.ec/server/api/v1';
  httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };

  constructor(private http: HttpClient) {}

  concatURL(subURL: string): string {
    return this.BASE_URL + subURL;
  }

  makePostRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.http.post(URL, data, headers);
  }

  makeGetRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.http.get(URL, headers);
  }

  makeDelRequest(URL: string, headers = this.httpOptions): Observable<any> {
    return this.http.delete(URL, headers);
  }

  makePutRequest(
    URL: string,
    data: any,
    headers = this.httpOptions
  ): Observable<any> {
    return this.http.put(URL, data, headers);
  }

  loginAdmin(email: string, password: string): Observable<any> {
    const auth = {
      email,
      password,
    };

    const PATH = this.concatURL('/auth/admin/signin');
    return this.makePostRequest(PATH, auth);
  }

  verifyToken(): Observable<any> {
    const PATH = this.concatURL('/user/me');
    return this.makeGetRequest(PATH);
  }

  editPost(post: Post, idPost: number): Observable<any> {
    const PATH = this.concatURL(`/posts/${idPost}`);
    return this.makePutRequest(PATH, post);
  }

  createPost(post: Post): Observable<any> {
    const PATH = this.concatURL(`/posts`);
    return this.makePostRequest(PATH, post);
  }

  deletePost(postId: number): Observable<any> {
    const PATH = this.concatURL(`/posts/${postId}`);
    return this.makeDelRequest(PATH);
  }

  deleteDenunciations(denunciationId: number): Observable<any> {
    const PATH = this.concatURL(`/denunciations/comments/${denunciationId}`);
    return this.makeDelRequest(PATH);
  }

  deleteTopic(topicId: number): Observable<any> {
    const PATH = this.concatURL(`/admin/topics/${topicId}`);
    return this.makeDelRequest(PATH);
  }
  deleteCommentary(commentaryId: number): Observable<any> {
    const PATH = this.concatURL(`/comments/${commentaryId}`);
    return this.makeDelRequest(PATH);
  }

  getComments(offset?: number, limit?: number): Observable<any> {
    let PATH = this.concatURL(`/denunciations/comments`);
    if (
      offset !== undefined &&
      offset !== null &&
      limit !== null &&
      limit !== undefined
    ) {
      PATH = PATH + `?offset=${offset}&limit=${limit}`;
    }
    return this.makeGetRequest(PATH);
  }

  getInfoComment(commentId: number): Observable<any> {
    const PATH = this.concatURL(`/comments/reported/${commentId}`);
    return this.makeGetRequest(PATH);
  }

  blockUser(codigoUsuario: string): Observable<any> {
    const PATH = this.concatURL(`/admin/blockUser`);
    return this.makePostRequest(PATH, { codigoUsuario });
  }

  createTraining(training: Training): Observable<any> {
    const PATH = this.concatURL(`/topics`);
    return this.makePostRequest(PATH, training);
  }

  editTraining(training: Training, trainingId: number): Observable<any> {
    const PATH = this.concatURL(`/topics/${trainingId}`);
    return this.makePutRequest(PATH, training);
  }

  createQuiz(quiz: Quiz): Observable<any> {
    const PATH = this.concatURL(`/quiz`);
    return this.makePostRequest(PATH, quiz);
  }

  createQuestion(question: QuestionQuiz): Observable<any> {
    const PATH = this.concatURL(`/questions`);
    return this.makePostRequest(PATH, question);
  }
  editQuestion(question: QuestionQuiz, questionId: number): Observable<any> {
    const PATH = this.concatURL(`/questions/${questionId}`);
    return this.makePutRequest(PATH, question);
  }
  deleteQuestion(questionId: number): Observable<any> {
    const PATH = this.concatURL(`/questions/${questionId}`);
    return this.makeDelRequest(PATH);
  }

  editQuiz(quiz: Quiz, quizId: number): Observable<any> {
    const PATH = this.concatURL(`/quiz/${quizId}`);
    return this.makePutRequest(PATH, quiz);
  }

  getUsers(type: string, offset?: number, limit?: number): Observable<any> {
    let PATH = this.concatURL(`/admin/users?type=${type}`);
    if (
      offset !== undefined &&
      offset !== null &&
      limit !== null &&
      limit !== undefined
    ) {
      PATH = PATH + `&offset=${offset}&limit=${limit}`;
    }
    console.log(PATH);

    return this.makeGetRequest(PATH);
  }

  getTrainings(offset?: number, limit?: number): Observable<any> {
    let PATH = this.concatURL(`/admin/topics`);
    if (
      offset !== undefined &&
      offset !== null &&
      limit !== null &&
      limit !== undefined
    ) {
      PATH = PATH + `?offset=${offset}&limit=${limit}`;
    }
    return this.makeGetRequest(PATH);
  }

  getTrainingById(id: number): Observable<any> {
    const PATH = this.concatURL(`/topics/${id}`);
    return this.makeGetRequest(PATH);
  }

  getTestTraining(trainingId: number): Observable<any> {
    const PATH = this.concatURL(`/topics/${trainingId}/quiz`);
    return this.makeGetRequest(PATH);
  }

  getPosts(offset?: number, limit?: number): Observable<any> {
    let PATH = this.concatURL(`/posts`);
    if (
      offset !== undefined &&
      offset !== null &&
      limit !== null &&
      limit !== undefined
    ) {
      PATH = PATH + `?offset=${offset}&limit=${limit}`;
    }
    return this.makeGetRequest(PATH);
  }

  getPostsByApproved(offset?: number, limit?: number): Observable<any> {
    let PATH = this.concatURL(`/posts/tobeapproved`);
    if (
      offset !== undefined &&
      offset !== null &&
      limit !== null &&
      limit !== undefined
    ) {
      PATH = PATH + `?offset=${offset}&limit=${limit}`;
    }
    return this.makeGetRequest(PATH);
  }

  getPosdtById(id: number) {
    const PATH = this.concatURL(`/posts/${id}`);
    return this.makeGetRequest(PATH);
  }

  getComplaintById(complaintId: number): Observable<any> {
    const PATH = this.concatURL(`/denunciations/comments/${complaintId}`);
    return this.makeGetRequest(PATH);
  }

  createUser(user: User): Observable<any> {
    const PATH = this.concatURL('/auth/signup');
    return this.makePostRequest(PATH, user);
  }

  getAdmins(offset?: number, limit?: number): Observable<any> {
    let PATH = this.concatURL(`/admin/getAdmins`);
    if (
      offset !== undefined &&
      offset !== null &&
      limit !== null &&
      limit !== undefined
    ) {
      PATH = PATH + `?offset=${offset}&limit=${limit}`;
    }
    console.log(PATH);

    return this.makeGetRequest(PATH);
  }

  getAdmin(code: string): Observable<any> {
    const path = this.concatURL(`/admin/getAdmin?codigoUsuario=${code}`);
    return this.makeGetRequest(path);
  }

  editAdmin(user: User): Observable<any> {
    const path = this.concatURL(`/admin/editAdmin/${user.id}`);
    return this.makePutRequest(path, user);
  }
}
