import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgxEditorModule } from 'ngx-editor';

import * as components from './components';
import { MessagesModule } from 'primeng/messages';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { NgxPaginationModule } from 'ngx-pagination';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NotContentComponent } from './components/not-content/not-content.component';

import { environment } from '@env/environment';

import { UploadFileComponent } from './components/upload-file/upload-file.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    CommonModule,
    NgxEditorModule,
    MessagesModule,
    ToastModule,
    CardModule,
    ConfirmDialogModule,
  ],
  declarations: [
    ...components.components,
    NotContentComponent,
    UploadFileComponent,
  ],
  exports: [
    CardModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxEditorModule,
    NgxPaginationModule,
    ConfirmDialogModule,
    ...components.components,
  ],
  providers: [ConfirmationService, MessageService],
})
export class SharedModule {}
