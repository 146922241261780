import { BlogService } from './../services/blog.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserResolver } from '../resolver/user.resolver';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private blog: BlogService) {}

  public setSession(token: string, rol: number): void {
    localStorage.setItem('token', token);
    localStorage.setItem('rol', rol.toString());
  }

  public logOut(): void {
    localStorage.clear();
    this.router.navigateByUrl('/login');
  }

  // public verifyToken(): Observable<any> {
  //   return this.blog.validToken();
  // }
}
