<!-- Dashboard nav -->
<app-side-bar></app-side-bar>
<!-- Dashboard nav end -->
<section class="global-container p-0">
  <!-- Header -->
  <app-header></app-header>
  <!-- Header end -->

  <!-- Dashboard content -->
  <router-outlet></router-outlet>
  <!-- Dashboard content end -->

  <!-- Copyrights -->

  <div class="copyrights container">
    <div class="text-center">
      <h3>Con el apoyo de:</h3>
      <img
        src="assets/images/auspicios_all_oim.png"
        alt="logo"
        class="footer_img"
      />
    </div>
    <p>
      2022 <i class="fa fa-copyright" aria-hidden="true"></i> Admin Panel by
      <a href="https://eiteck-page.web.app/home" target="_blank"
        >Eitecknologia</a
      >
    </p>
  </div>
</section>
