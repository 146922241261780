import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  openMenu = false;
  openMenuAdmin = false;
  openNav = false;
  public rolId = localStorage.getItem('rol');
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    //console.log(this.rolId);
  }

  logOut(): void {
    this.authService.logOut();
  }
}
