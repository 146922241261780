<div class="pagination__wrapper mar-top-30">
  <ul class="pagination">
    <li>
      <button
        class="prev"
        title="previous page"
        [disabled]="current === 0"
        (click)="changePage(current - 1)"
      >
        &#10094;
      </button>
    </li>
    <li *ngFor="let item of items">
      <button
        [ngClass]="item === current + 1 ? 'active' : ''"
        (click)="changePage(item - 1)"
      >
        {{ item }}
      </button>
    </li>

    <li>
      <button
        class="next"
        title="next page"
        [disabled]="total - 1 === current"
        (click)="changePage(current + 1)"
      >
        &#10095;
      </button>
    </li>
  </ul>
</div>
