import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { BlogService } from '../services/blog.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GuardGuard implements CanActivate {
  token: string;
  constructor(
    private blogService: BlogService,
    private authService: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.blogService.verifyToken().pipe(
      map((resp) => true),
      catchError((err) => {
        this.authService.logOut();
        return of(false);
      })
    );

    // return this.vitaappService.validToken().pipe(
    //   map((resp) => {
    //     if (resp) {
    //       return true;
    //     } else {
    //       this.authService.logOut();
    //       return false;
    //     }
    //   }),
    //   catchError(() => {
    //     this.authService.logOut();
    //     return of(false);
    //   })
    // );
  }
}
