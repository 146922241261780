import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
interface TotalItem {
  totalItems: number;
}
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() totalItems: TotalItem;
  @Input() itemsPage: number;
  @Input() numberShowPages: number = 5;
  @Input() current: number = 0;
  @Output() currentChange: EventEmitter<number> = new EventEmitter<number>();
  total = 0;
  items = [];

  constructor() {}

  ngOnInit(): void {
    this.total = Math.ceil(this.totalItems.totalItems / this.itemsPage);
    this.changePage(this.current);
  }

  changePage(page: number): void {
    if (page !== this.current) {
      this.currentChange.emit(page);
    }
    this.current = page;

    if (this.numberShowPages < this.total) {
      if (page < this.numberShowPages / 2) {
        this.items = new Array(this.numberShowPages)
          .fill(0)
          .map((item, index) => index + 1);
      } else if (this.total - page < this.numberShowPages / 2) {
        this.items = new Array(this.numberShowPages)
          .fill(0)
          .map((item, index) => this.total - this.numberShowPages + index + 1);
      } else {
        this.items = new Array(this.numberShowPages)
          .fill(0)
          .map(
            (item, index) =>
              this.current - Math.floor(this.numberShowPages / 2) + index + 1
          );
      }
    } else {
      this.items = new Array(this.total)
        .fill(0)
        .map((item, index) => index + 1);
    }
  }
}
