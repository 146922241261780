import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @ViewChild('toast') toast: ElementRef<HTMLElement>;
  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    // document.body.appendChild(this.toast);
  }

  createMessage(message: Message) {
    this.showMessage(message);
  }

  showMessage(msg: Message) {
    this.messageService.add({
      key: 'toastMessage',
      ...msg,
    });
  }
}
