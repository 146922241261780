import { ComplaintModule } from './modules/complaint/complaint.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SkeletonComponent } from '@layout/skeleton/skeleton.component';
import { GuardGuard } from './services/guard/guard.guard';
import { UserResolver } from './services/resolver/user.resolver';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: SkeletonComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('@modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        resolve: { user: UserResolver },
      },
      {
        path: '',
        loadChildren: () =>
          import('@modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('@modules/add-post/add-post.module').then(
            (m) => m.AddPostModule
          ),
      },
      {
        path: 'news',
        loadChildren: () =>
          import('@modules/news/news.module').then((m) => m.NewsModule),
      },
      {
        path: '',
        loadChildren: () =>
          import('@modules/training/training.module').then(
            (m) => m.TrainingModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('@modules/complaint/complaint.module').then(
            (m) => m.ComplaintModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('@modules/gestion-list/gestion-list.module').then(
            (m) => m.GestionListModule
          ),
      },
      {
        path: 'reviews',
        loadChildren: () =>
          import('@modules/reviews/reviews.module').then(
            (m) => m.ReviewsModule
          ),
      },
      {
        path: 'descargas',
        loadChildren: () =>
          import('@modules/downloads/downloads.module').then(
            (m) => m.DownloadsModule
          ),
      },
      { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
    ],
    canActivate: [GuardGuard],
  },
  { path: '**', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
