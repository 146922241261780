<div class="progress mt-3">
  <div
    class="progress-bar"
    role="progressbar"
    [ngStyle]="{ width: this.percentage + '%' }"
    attr.aria-valuenow="{{ this.percentage }}"
    aria-valuemin="0"
    aria-valuemax="100"
  ></div>
</div>
