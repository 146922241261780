<!-- Responsive Navigation Trigger -->
<a class="dashboard-responsive-nav-trigger" (click)="openNav = !openNav"
  ><i class="fa fa-reorder"></i> Dashboard Navigation</a
>

<div class="dashboard-sticky-nav">
  <div class="content-left pull-left">
    <a href="dashboard.html">
      <h3>D<span>iasporaecuador</span></h3></a
    >
  </div>
  <div class="content-right pull-right">
    <div class="search-bar">
      <form>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            id="search"
            placeholder="Search Now"
          />
          <a href="#"
            ><span class="search_btn"
              ><i class="fa fa-search" aria-hidden="true"></i></span
          ></a>
        </div>
      </form>
    </div>

    <div class="dropdown">
      <a
        class="dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="profile-sec">
          <div class="dash-image">
            <img src="assets/images/user.svg" alt="" />
          </div>
          <div class="dash-content">
            <h4>Usuario Admin</h4>
            <span>Administrador</span>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
<div [ngClass]="openNav ? 'dashboard-nav active' : 'dashboard-nav'">
  <div class="dashboard-nav-inner">
    <ng-container *ngIf="rolId === '2'; else noSuperAdmin">
      <ul>
        <li [routerLinkActive]="['active']">
          <a routerLink="/dashboard"><i class="fas fa-cog"></i> Dashboard</a>
        </li>
        <!-- <li [routerLinkActive]="['active']">
          <a routerLink="/profile"><i class="far fa-user"></i> Agregar Admin</a>
        </li> -->
        <li [ngClass]="openMenuAdmin ? 'active' : ''">
          <a (click)="openMenuAdmin = !openMenuAdmin"
            ><i class="far fa-user"></i> Gestionar Administradores</a
          >
          <ul>
            <li>
              <a routerLink="/profile">Agregar Administrador </a>
            </li>
            <li>
              <a routerLink="/list-users">Listar Administradores</a>
            </li>
          </ul>
        </li>
        <li [routerLinkActive]="['active']">
          <a routerLink="/add"><i class="fas fa-plus"></i> Agregar Post</a>
        </li>
        <li [routerLinkActive]="['active']">
          <a routerLink="/news/agregar"
            ><i class="fas fa-plus"></i> Agregar Noticia</a
          >
        </li>
        <li [ngClass]="openMenu ? 'active' : ''">
          <a (click)="openMenu = !openMenu"
            ><i class="fas fa-list"></i> Listas</a
          >
          <ul>
            <li>
              <a routerLink="/lista-post">Lista de Post </a>
            </li>
            <li>
              <a routerLink="/news">Lista de Noticias </a>
            </li>
            <li>
              <a routerLink="/lista-capacitaciones">Lista de Capacitaciones </a>
            </li>
            <li>
              <a routerLink="/lista-denuncias">Comentarios Bloqueados </a>
            </li>
          </ul>
        </li>
        <li [routerLinkActive]="['active']">
          <a routerLink="/capacitacion"
            ><i class="pi pi-file-o"></i> Capacitación</a
          >
        </li>
        <li [routerLinkActive]="['active']">
          <a routerLink="/descargas"><i class="pi pi-download"></i>Descargas</a>
        </li>
        <li>
          <a (click)="logOut()"><i class="fas fa-power-off"></i> Salir</a>
        </li>
      </ul>
    </ng-container>

    <ng-template #noSuperAdmin
      ><ul>
        <li [routerLinkActive]="['active']">
          <a routerLink="/descargas"><i class="pi pi-download"></i>Descargas</a>
        </li>
        <li>
          <a (click)="logOut()"><i class="fas fa-power-off"></i> Salir</a>
        </li>
      </ul>
    </ng-template>
  </div>
</div>
