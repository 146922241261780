import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Message } from 'primeng/api';
import { Subscriber, Subscription } from 'rxjs';
import { UrlData } from 'src/app/services/interface/url-data.interface';
import { FileUpload } from 'src/app/services/models/FileUpload';
import { FirebaseService } from 'src/app/services/services/firebase.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  // ------
  @Output() path: EventEmitter<UrlData> = new EventEmitter<UrlData>();
  @Output() message: EventEmitter<Message> = new EventEmitter<Message>();
  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  percentage = 0;
  getPath = false;
  subscriber: Subscription;

  constructor(private firebase: FirebaseService) {}

  ngOnInit(): void {}

  upload(): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;

      if (file) {
        const originalName = file.name;
        this.getPath = false;
        this.currentFileUpload = new FileUpload(file);
        this.subscriber = this.firebase
          .pushFileToStorage(this.currentFileUpload)
          .subscribe(
            (resp) => {
              this.percentage = (resp.bytesTransferred * 100) / resp.totalBytes;
              if ((resp.bytesTransferred * 100) / resp.totalBytes > 99) {
                resp.ref
                  .getDownloadURL()
                  .then((path: string) => {
                    console.log(path);
                    if (!this.getPath) {
                      this.getPath = true;
                      this.path.emit({url: path, name: originalName});
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
              // * Una vez la subida finaliza se guarda el URL que nos devuelve firebase para guardar las imagenes.
            },
            (error) => {
              const msg = {
                severity: 'error',
                summary: 'Error',
                detail: 'Error al subir el archivo',
              };
              this.message.emit(msg);
            }
          );
      }
    }
  }

  saveFileFirebase(event: any): void {
    this.selectedFiles = event.target.files;
    this.upload();
  }
}
