// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBJTbb4meCfDQG-hVy4tG7Ax6HEbpx0B4M',
    authDomain: 'blog-eiteck.firebaseapp.com',
    projectId: 'blog-eiteck',
    storageBucket: 'blog-eiteck.appspot.com',
    messagingSenderId: '410357744084',
    appId: '1:410357744084:web:459b4ff2018477c0e99641',
    measurementId: 'G-CS0KJDTGG0',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
