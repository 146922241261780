import { AuthService } from './../auth/auth.service';
import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { BlogService } from '../services/blog.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<boolean> {
  token: string;

  constructor(
    private blogService: BlogService,
    private authService: AuthService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    console.log('entre resolver');
    // if(localStorage.getItem('token'))

    if (localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      return this.blogService.verifyToken().pipe(
        map((resp) => {
          return true;
        }),
        catchError((err) => {
          this.authService.logOut();
          return of(false);
        })
      );
    }
  }
}
